// Generated by Framer (19eed98)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["EoWZfF5GJ"];

const serializationHash = "framer-zfWHL"

const variantClassNames = {EoWZfF5GJ: "framer-v-1nqmq7u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "EoWZfF5GJ", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-zfWHL", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1nqmq7u", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"EoWZfF5GJ"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1n9xfzs"} data-framer-name={"shape-arrow 12"} layout={"position"} layoutDependency={layoutDependency} layoutId={"EoWZfF5GJIejUsR4o3"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 12.426 23.262 L 21.262 14.426 C 21.555 14.134 21.555 13.659 21.262 13.366 C 20.969 13.073 20.494 13.073 20.201 13.366 L 12.646 20.921 L 12.646 2.104 C 12.646 1.69 12.31 1.354 11.896 1.354 C 11.482 1.354 11.146 1.69 11.146 2.104 L 11.146 20.921 L 3.591 13.366 C 3.298 13.073 2.823 13.073 2.53 13.366 C 2.238 13.659 2.238 14.134 2.53 14.426 L 11.366 23.262 C 11.659 23.555 12.134 23.555 12.426 23.262 Z\" fill=\"var(--token-be079bad-eabd-4234-8a56-5579c7057187, rgb(0, 0, 0)) /* {&quot;name&quot;:&quot;Primary / Black&quot;} */\"></path></svg>"} svgContentId={468073241} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-zfWHL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zfWHL .framer-9x62pa { display: block; }", ".framer-zfWHL .framer-1nqmq7u { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-zfWHL .framer-1n9xfzs { flex: none; height: 24px; left: calc(50.00000000000002% - 24px / 2); position: absolute; top: calc(50.00000000000002% - 24px / 2); width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerW_MR005QJ: React.ComponentType<Props> = withCSS(Component, css, "framer-zfWHL") as typeof Component;
export default FramerW_MR005QJ;

FramerW_MR005QJ.displayName = "Arrow - Down - 24";

FramerW_MR005QJ.defaultProps = {height: 24, width: 24};

addFonts(FramerW_MR005QJ, [])